import { css } from "@emotion/core";

export const roomStyle = css`
  position: relative;
  background: black;
  color: white;
  text-align: center;
  padding: 40px;
  height: 100vh;
  width: 100vw;

  h1 {
    z-index: 1;
    margin-top: 20px;
    color: white;
    text-align: center;
    position: relative;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), 0 2px 12px rgba(0, 0, 0, 0.3);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .wallpaper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    object-fit: cover;

    &.-rotate,
    &.-eight,
    &.-six {
      height: 100vw;
      width: 100vh;
    }
  }

  .extra-buttons {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    font-size: 12px;
    padding: 5px;

    .room,
    .coffee,
    .share,
    .edit {
      opacity: 0.4;
      padding: 4px;
      cursor: pointer;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), 0 2px 12px rgba(0, 0, 0, 0.3);

      i {
        margin-right: 5px;
      }

      a {
        color: white;
        text-decoration: none !important;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .members {
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    border-radius: 4px;
    max-height: 60%;
    overflow: scroll;
  }

  .info {
    position: absolute;
    top: 100px;
    left: 20px;
    width: calc(100% - 40px);
    // height: max(120px, calc(100% - 660px));
    height: max(120px, 25%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), 0 2px 12px rgba(0, 0, 0, 0.3);

    .small-info {
      display: none;
    }
  }

  .DJoverlay {
    z-index: 2;
    position: absolute;
    // left: 20px;
    left: max(20px, 25% - 250px);
    bottom: 20px;
    // width: calc(100% - 460px);
    width: calc(50% - 40px);
    max-width: 500px;
    // height: calc(70% - 20px);
    // height: calc(100% - 260px);
    // max-height: 520px;
    height: 60%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.-hidden {
      display: none;
    }
  }

  .DJ {
    overflow: scroll;
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: calc(50% - 40px);
    height: 60%;
    // height: calc(100% - 260px);
    // max-height: 520px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .playcard {
      text-align: left;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 500px;
      // max-width: 400px;
      margin: 0 auto;
      i {
        margin-right: 10px;
      }
    }

    .scrub {
      position: relative;
      margin-top: 5px;
      width: 100%;
      max-width: 500px;
      // max-width: 400px;
      height: 8px;
      background: rgba(254, 254, 254, 0.5);
      margin: 0 auto;
      border-radius: 4px;

      .progress,
      .loaded {
        height: 100%;
        background: rgba(254, 254, 254, 0.3);
        border-radius: 4px;
      }

      .progress {
        top: 0;
        position: absolute;
        background: white;
      }
    }

    .row {
      max-width: 500px;
      // max-width: 400px;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;

      .controls {
        display: flex;
        width: 120px;
        align-items: center;
        justify-content: space-between;

        i {
          font-size: 20px;
          cursor: pointer;
        }
      }
    }

    .add-queue {
      cursor: pointer;
      font-weight: 600;
    }

    .ReactPlayer {
      height: 100%;
      max-width: 500px;
      max-height: 282px;
      margin: 0 auto;
      margin-bottom: 20px;
      position: relative;
      // padding-top: 56.25%;

      .react-player {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
      }

      // display: none;
    }

    iframe {
      // display: none;
    }
  }

  .name {
    overflow: scroll;
    position: fixed;
    bottom: 20px;
    width: calc(100% - 40px);
    // max-width: 500px;
    // right: max(calc(50% - 250px), 0px);
    right: 20px;
    // height: calc(100% - 260px);
    // max-height: 520px;
    height: 60%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.-hidden {
      display: none;
    }

    input {
      text-align: center;
      padding: 10px;
      background: rgba(0, 0, 0, 0.5);
      border: none;
      color: white;
      width: 300px;
      margin-bottom: 20px;
    }

    button {
      color: white;
      background: black;
      height: 44px;
      width: 300px;
    }

    .-hidden {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  .communication {
    position: fixed;
    right: 20px;
    width: calc(50% - 40px);
    // height: calc(100% - 260px);
    height: 60%;
    // max-height: 520px;
    bottom: 20px;
  }

  .chat {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    height: 100%;
    // max-height: 520px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));

    .messages {
      overflow-y: scroll;
      position: absolute;
      right: 0px;
      bottom: 50px;
      width: 100%;
      height: calc(100% - 50px);
      min-height: 0;
    }

    .message {
      padding: 5px 15px;
      text-align: left;
      width: 100%;
    }
  }

  .chat-row {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  .chat-input {
    height: 50px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - 50px);
    background: none;
    border: none;
    color: white;
    padding: 10px 15px;
  }

  .chat-send {
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 50px;
    width: 50px;
    background: none;
    color: white;
    border: none;
  }

  @media only screen and (max-width: 820px) {
    h1 {
      margin-top: 5px;
    }

    .info {
      // height: 100px;
      height: 80px;
      top: 70px;

      .small-info {
        display: block;
      }

      .big-info {
        display: none;
      }
    }

    .extra-buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 16px;

      i {
        margin: 0 !important;
      }

      .extra-button {
        margin: 0 15px;
        text-align: center;
      }
    }

    .extra-button-text {
      display: none;
    }

    .communication {
      // height: calc(100% - 410px);
      height: calc(100% - 450px);
      right: 20px;
      width: calc(100% - 40px) !important;
      max-width: calc(100% - 40px) !important;
    }

    .chat,
    .chat-row {
      max-width: 100%;
    }

    .name {
      height: calc(100% - 180px);
      max-height: 100%;
      // right: 0;
      // width: 100%;
      // max-width: 100%;
    }

    .messages {
      width: 100% !important;
    }

    .add-queue {
      display: none;
    }

    .DJoverlay {
      left: 20px;
      // height: 110px;
      height: 320px;
      // top: 160px;
      top: 130px;
      width: calc(100% - 40px);
      max-width: 100%;
      // left: 0px;
    }

    .add-button-big {
      display: none;
    }

    .DJ {
      z-index: 1;
      left: 30px;
      // height: 100px;
      height: 320px;
      // top: 170px;
      top: 140px;
      width: calc(100% - 60px);

      .row {
        flex-wrap: wrap;
      }

      .playcard,
      .scrub,
      .row {
        max-width: calc(100% - 20px);

        .controls {
          // margin: 0 auto;
          // width: 50px;
        }

        i.fa-video,
        .fa-video-slash {
          // display: none;
        }
      }

      .playlist {
        display: none;
      }

      .ReactPlayer {
        max-height: 160px;
        margin: 0 auto;
        margin-bottom: 8px;
        // display: none !important;
      }
    }
  }
`;
