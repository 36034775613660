import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { isLoaded } from "react-redux-firebase";
import ls from "local-storage";

import PageAuth from "pages/PageAuth";
import PageHome from "pages/PageHome";
import PageRoom from "pages/PageRoom";

import Loading from "components/Loading";
import {
  PrivateRoute,
  PublicRoute,
  CustomRoute
} from "components/PrivateRoute";

import { Route, Switch, Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";

const uuidv1 = require("uuid/v1");

class Root extends React.Component {
  state = { userID: null };

  componentDidMount() {
    const userID = ls.get("userID");

    if (!userID) {
      const newID = uuidv1();
      ls.set("userID", newID);
      this.setState({ userID: newID });
    } else {
      this.setState({ userID });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { settings } = this.props;
    const { userID } = this.state;

    if (!userID) {
      return (
        <div style={{ maxWidth: 500, margin: "20% auto" }}>
          <Loading type="spin" />
        </div>
      );
    }

    return (
      <div className="Root">
        <Switch>
          <Route
            exact
            path="/(login|register|auth|reset)/"
            component={PageAuth}
          />
          <Route
            exact
            path="/"
            render={props => <PageHome {...props} userID={userID} />}
          />
          <Route
            exact
            path="/r/:rid"
            render={props => (
              <PageRoom {...props} {...props.match.params} userID={userID} />
            )}
          />
          <Route component={() => <Redirect to="/" />} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  // console.log(state, props);
  return {
    email: state.firebase.auth.email,
    isLoaded: state.firebase.auth.isLoaded && state.firebase.profile.isLoaded,
    isLoggedIn: state.firebase.auth.uid ? true : false,
    emailVerified: state.firebase.auth.emailVerified,
    uid: state.firebase.auth.uid,
    settings: state.firestore.data.settings,
    profile: state.firebase.profile
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default compose(
  firebaseConnect((props, ...rest) => {
    return [];
  }),
  firestoreConnect((props, ...rest) => [
    {
      collection: "settings",
      doc: "config",
      storeAs: "settings"
    }
  ]),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Root);
