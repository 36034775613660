import * as React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";

class ExtraButtons extends React.Component {
  state = { copied: false };

  render() {
    return (
      <div className="extra-buttons">
        <div
          className="room extra-button"
          data-tip={"Create your own chill sessions"}
        >
          {/*<a href={window.location.origin} target="_blank">
            <i className="fas fa-kiwi-bird"></i>
            <span className="extra-button-text">Rejoin or create a room</span>
          </a>*/}
          <Link to="/">
            <i className="fas fa-kiwi-bird"></i>
            <span className="extra-button-text">Rejoin or create a room</span>
          </Link>
        </div>
        <div
          className="share extra-button"
          data-tip={"Saves room's url to clipboard to share"}
        >
          <CopyToClipboard
            text={window.location.origin + window.location.pathname}
            onCopy={() => this.setState({ copied: true })}
          >
            <span>
              <i
                className={
                  this.state.copied ? "fas fa-clipboard" : "far fa-clipboard"
                }
              ></i>{" "}
              <span className="extra-button-text">
                {this.state.copied
                  ? "Copied link to clipboard"
                  : "Copy room link to share"}
              </span>
            </span>
          </CopyToClipboard>
        </div>
        {this.props.isAdmin ? (
          <div
            className="edit extra-button"
            data-tip={"Edit this room (only creator and admins can edit)"}
            onClick={this.props.editRoom}
          >
            <i className="fas fa-edit"></i>
            <span className="extra-button-text">Edit room</span>
          </div>
        ) : (
          <div
            className="edit extra-button"
            data-tip={"Message the host if you want room changes"}
          >
            <i className="fas fa-edit"></i>
            <span className="extra-button-text">
              Hosted by {this.props.names[this.props.room.owner] || "someone"}
            </span>
          </div>
        )}
        <div className="coffee extra-button">
          <a href="https://www.ryanylee.com" target="_blank">
            <i className="far fa-heart"></i>
            <span className="extra-button-text">Created by Ryan</span>
          </a>
        </div>
      </div>
    );
  }
}

export default ExtraButtons;
