import * as StripeActions from 'store/actions/stripe_actions';

const DEFAULT = {
  subscription: null,
  customer: null,
};

export default function(state = DEFAULT, action) {
  switch (action.type) {
    case StripeActions.SAVE_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      };
    case StripeActions.DELETE_SUBSCRIPTION:
      return {
        ...state,
        subscription: null,
      };
    case StripeActions.SAVE_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    default:
      return state;
  }
}
