/*

Framework created by Ryan Lee, use only allowed with permission from Ryan Lee.

*/

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Root from "pages/Root";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";

const App = ({ store, rrfProps }) => (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Router>
        <Route path="/" component={Root} />
      </Router>
    </ReactReduxFirebaseProvider>
  </Provider>
);

export default App;
