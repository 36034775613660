import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Mixpanel } from "utils/mixpanel";
import GoogleButton from "components/GoogleButton";
import Loading from "components/Loading";
import Logo from "components/Logo";

export default class PageRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      loading: false
    };
  }

  onFormSubmit = event => {
    event.preventDefault();

    const email = this.refs.email.value;
    const password = this.refs.password.value;
    const confirmPassword = this.refs["confirm-password"].value;

    this.setState({ loading: true });

    if (password === confirmPassword) {
      const parts = email.split("@");

      if (parts[0].includes("+")) {
        this.setState({
          message: "No special characters like '+' allowed in email",
          loading: false
        });
        return;
      } else if (parts[0].includes(".")) {
        this.setState({
          message: "No special characters like '.' allowed in email",
          loading: false
        });
        return;
      }

      this.props
        .registerUser({ email, password })
        .then(data => {
          this.props.firebase
            .auth()
            .currentUser.sendEmailVerification()
            .then(() => {
              console.log("verification email sent");
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(error => {
          this.setState({ message: error.message, loading: false });
        });
      // Mixpanel.alias(email);
      // Mixpanel.track('user_signup', { email: email });
    } else {
      this.setState({ message: "Passwords do not match", loading: false });
    }
  };

  loginWithProvider = provider => {
    this.setState({ loading: true });

    this.props
      .loginUser({ provider })
      .then(data => {
        let email = data.user.user.email;
        Mixpanel.alias(email);
        Mixpanel.track("user_signup", { email: email });
      })
      .catch(error => {
        this.setState({ message: error.message, loading: false });
      });
  };

  render() {
    return (
      <div className="PageRegister">
        <div className="Box" style={{ width: "100%" }}>
          <form onSubmit={this.onFormSubmit}>
            <Logo large flat center />
            <br />
            <br />
            <h1>Sign Up</h1>
            <br />
            <input type="email" ref="email" placeholder="Email" />
            <br />
            <br />
            <input type="password" ref="password" placeholder="Password" />
            <br />
            <br />
            <input
              type="password"
              ref="confirm-password"
              placeholder="Confirm Password"
            />
            <br />
            <br />
            {!this.state.loading ? (
              <div>
                <button type="submit">Sign Up</button>
                <br />
                {this.state.message && (
                  <div className="Warning">
                    <br />
                    {this.state.message}
                  </div>
                )}
                <br />
                <GoogleButton
                  onClick={() => {
                    this.loginWithProvider("google");
                  }}
                />
              </div>
            ) : (
              <Loading style={{ height: 148 }} />
            )}
          </form>
        </div>
        <br />
        <Link to="/login">Already have an account? Log in</Link>
        <br />
        <div style={{ height: 10 }} />
        <Link to="/reset">Forgot password? Reset</Link>
        <br />
      </div>
    );
  }
}
