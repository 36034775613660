import {
  takeEvery,
  takeLatest,
  put,
  call,
  all,
  select,
} from 'redux-saga/effects';
import { apiPost, apiGet, apiDelete } from 'utils/Api';
import * as MainActions from 'store/actions/main_actions';

export default function*() {
  yield all([]);
}
