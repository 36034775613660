import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

class Playlist extends React.Component {
  render() {
    const { radio, removeSong, show } = this.props;
    return (
      <div
        className="playlist"
        style={{
          maxWidth: 500,
          width: "100%",
          margin: "10px auto 10px",
          textAlign: "left",
          marginBottom: radio.length > 0 ? 20 : 0,
        }}
      >
        {radio.length > 0 && <b>Up Next</b>}
        {radio.map((song) => {
          return (
            <div
              style={{
                width: "100%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                padding: 10,
                background: "rgba(0, 0, 0, 0.5)",
                color: "white",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <a
                href={song.value.url}
                target="_blank"
                style={{
                  width: "calc(100% - 44px)",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {song.value.url}
              </a>
              <div
                style={{
                  height: 44,
                  width: 44,
                  position: "absolute",
                  top: 0,
                  right: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {show && (
                  <i
                    className="fas fa-times-circle"
                    style={{
                      cursor: "pointer",
                      color: "white",
                    }}
                    onClick={() => removeSong(song.key, song.value.url)}
                  ></i>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const radio = state.firebase.ordered.radio || [];

  return {
    radio,
    removeSong: (id, url) => {
      props.sendMessage(`**removed ${url} from playlist**`);
      props.firebase.update("/radio/" + props.roomID, {
        [id]: null,
      });
    },
  };
}

export default compose(
  withRouter,
  firebaseConnect((props) => [
    {
      path: "/radio/" + props.roomID,
      storeAs: "radio",
      queryParams: [
        "orderByChild=timestamp",
        `startAt=${props.timestamp + 1}`,
        "limitToFirst=20",
      ],
    },
  ]),
  firestoreConnect(),
  connect(mapStateToProps)
)(Playlist);
