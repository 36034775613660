import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import main_reducer from './main_reducer';
import stripe_reducer from './stripe_reducer';

import { combineReducers } from 'redux';

const reducers = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  stripe: stripe_reducer,
});

const rootReducer = (state, action) => {
  if (
    action.type === 'RESET_REDUX' ||
    action.type === 'LOGOUT_FIREBASE_USER' ||
    action.type === 'DELETE_ACCOUNT'
  ) {
    state = undefined;
  }

  return reducers(state, action);
};

export default rootReducer;
