import * as React from "react";
import { userCount } from "./helpers.js";
import Modal from "react-bootstrap/Modal";

export default class InfoHelper extends React.Component {
  state = { showMembers: false };

  userCount = () => {
    const { stats, names, userID } = this.props;
    return userCount({ stats, names, userID });
  };

  render() {
    return (
      <div className="info">
        <div
          className="big-info"
          style={{ cursor: "pointer" }}
          data-tip={"see everyone online"}
          onClick={() => this.setState({ showMembers: true })}
        >
          {this.userCount()}
        </div>
        <div
          className="small-info"
          style={{ cursor: "pointer" }}
          data-tip={"see everyone online"}
          onClick={() => this.setState({ showMembers: true })}
        >
          {Object.keys(this.props.stats).length}
          {Object.keys(this.props.stats).length === 1
            ? " person is online"
            : " people are online"}
        </div>
        <Modal
          show={this.state.showMembers}
          onHide={() => this.setState({ showMembers: false })}
        >
          {/*<div style={{ textAlign: "right" }}>
            <i
              className="fas fa-times"
              style={{ cursor: "pointer", fontSize: 20 }}
              onClick={() => this.setState({ showMembers: false })}
            ></i>
          </div>*/}
          <div className="members">
            {Object.keys(this.props.stats).map((id) => {
              if (this.props.names[id]) {
                return (
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "5px",
                      paddingBottom: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    {this.props.names[id]}
                    {(this.props.isAdmin || id === this.props.userID) && (
                      <i
                        className="fas fa-times"
                        style={{
                          cursor: "pointer",
                          marginLeft: "auto",
                        }}
                        onClick={() => this.props.removeMember(id)}
                      ></i>
                    )}
                    {(this.props.isAdmin && id !== this.props.userID) && (
                      <i
                        className="fas fa-ban"
                        style={{
                          cursor: "pointer",
                          marginLeft: 10,
                        }}
                        data-tip={"Permanently ban. Careful, it's irreversible!"}
                        onClick={() => this.props.blockMember(id)}
                      ></i>
                    )}
                  </div>
                );
              } else {
                return (
                  <div
                    style={{
                      marginBottom: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {"Anonymous user"}
                  </div>
                );
              }
            })}
          </div>
        </Modal>
      </div>
    );
  }
}
