import * as React from "react";

export default class Chat extends React.Component {
  state = { message: "", num: 50 };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const newChat = this.props.chat;
    const oldChat = prevProps.chat;

    if (
      newChat &&
      oldChat &&
      (newChat[newChat.length - 1] || { value: "" }).value.text !==
        (oldChat[oldChat.length - 1] || { value: "" }).value.text
    ) {
      this.messagesEnd && this.messagesEnd.scrollIntoView();
    }
  }

  sendMessage = () => {
    const { sendMessage, names, userID } = this.props;
    const yourName = names && names[userID];
    if (!yourName) return null
    names[userID] && sendMessage(this.state.message);
    this.setState({ message: "" });
  };

  handleScroll = (event) => {
    if (
      event.target.scrollTop === 0 &&
      this.props.chat &&
      this.props.chat.length >= 49
    ) {
      console.log(true);
    }
  };

  render() {
    const yourName = this.props.names && this.props.names[this.props.userID];

    if (yourName === "blocked!") {
      return null
    }

    return (
      <div className="communication">
        <div className={yourName ? "chat" : "chat blur"}>
          <div className="messages gradient" onScroll={this.handleScroll}>
            {this.props.chat && (
              <div style={{ height: "50%" }} />
            )}
            {this.props.chat.map((message) => {
              const { text, uid } = message.value;
              if (
                this.props.names &&
                (this.props.names[uid] === "blocked!" || !this.props.names[uid])
              ) {
                return null
              }
              return (
                <div className="message" key={message.key}>
                  <b>{(this.props.names && this.props.names[uid]) || "Anon"}</b>
                  {": "} {text}
                </div>
              );
            })}
            <div
              ref={(el) => {
                this.messagesEnd = el;
              }}
            />
          </div>
        </div>
        <div className="chat-row">
          <input
            className={yourName ? "chat-input" : "chat-input blur"}
            value={this.state.message}
            placeholder="Type a message"
            onKeyPress={(e) => e.key === "Enter" && this.sendMessage()}
            onChange={(e) =>
              yourName && this.setState({ message: e.target.value })
            }
          />
          <button
            className={yourName ? "chat-send" : "chat-send blur"}
            onClick={this.sendMessage}
          >
            <i className="fas fa-paper-plane"></i>
          </button>
        </div>
      </div>
    );
  }
}
