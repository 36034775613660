/** @jsx jsx **/

import * as React from "react";
import { Link } from "react-router-dom";
import { jsx, css } from "@emotion/core";

const logoStyle = css`
  a {
    text-decoration: none;
  }

  h1 {
    margin: 0;
  }
`;

export default class Logo extends React.Component {
  render() {
    if (this.props.flat) {
      return (
        <div css={logoStyle}>
          <Link to={this.props.to ? this.props.to : "/"}>
            <h1 onClick={this.props.onClick}>Senior Sale</h1>
          </Link>
        </div>
      );
    }

    return (
      <div css={logoStyle}>
        <Link to={this.props.to ? this.props.to : "/"}>
          <h1 onClick={this.props.onClick}>Senior Sale</h1>
        </Link>
      </div>
    );
  }
}
