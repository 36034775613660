export const GET_CUSTOMER = 'GET_CUSTOMER';
export const SAVE_CUSTOMER = 'SAVE_CUSTOMER';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const SAVE_SUBSCRIPTION = 'SAVE_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const UNCANCEL_SUBSCRIPTION = 'UNCANCEL_SUBSCRIPTION';
export const GET_ALL = 'GET_ALL';

export function getAll(payload = { customer: {}, subscription: {} }) {
  return {
    type: GET_ALL,
    payload: payload,
  };
}

export function deleteSubscription(payload = { id: null }) {
  return {
    type: DELETE_SUBSCRIPTION,
    payload,
  };
}

export function cancelSubscription(payload = { id: null }) {
  return {
    type: CANCEL_SUBSCRIPTION,
    payload,
  };
}

export function uncancelSubscription(payload = { id: null }) {
  return {
    type: UNCANCEL_SUBSCRIPTION,
    payload,
  };
}

export function getSubscription(payload = {}) {
  return {
    type: GET_SUBSCRIPTION,
    payload,
  };
}

export function saveSubscription(payload = {}) {
  return {
    type: SAVE_SUBSCRIPTION,
    payload,
  };
}

export function getCustomer(payload = {}) {
  return {
    type: GET_CUSTOMER,
    payload,
  };
}

export function saveCustomer(payload = {}) {
  return {
    type: SAVE_CUSTOMER,
    payload,
  };
}
