import React, { Component } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import Logo from "components/Logo";
import Loading from "components/Loading";

export default class PagePasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = { message: "", sent: false, loading: false };
  }

  onFormSubmit = event => {
    event.preventDefault();
    this.setState({ loading: true });
    const email = this.refs.email.value;
    var auth = this.props.auth();
    auth
      .sendPasswordResetEmail(email)
      .then(data => {
        this.setState({ sent: true, loading: false });
      })
      .catch(error => {
        this.setState({ message: error.message, loading: false });
      });
  };

  onFormSubmitConfirm = (event, oobCode) => {
    event.preventDefault();
    this.setState({ loading: true });
    const password = this.refs.password.value;
    const confirmPassword = this.refs["confirm-password"].value;

    if (password === confirmPassword) {
      this.props
        .confirmPasswordReset(oobCode, password)
        .then(data => {
          window.alert("Password reset confirmed!");
          this.setState({ loading: false });
          this.props.history.push("/login");
        })
        .catch(error => {
          this.setState({ message: error.message, loading: false });
        });
    } else {
      this.setState({ message: "Passwords do not match" });
    }
  };

  renderForm = () => {
    return (
      <div>
        <form onSubmit={this.onFormSubmit}>
          <Logo large flat center />
          <br />
          <br />
          <h1>Reset Password</h1>
          <br />
          {!this.state.sent ? (
            <div>
              <input type="email" ref="email" placeholder="Email" />
              <br />
              <br />
              {!this.state.loading ? (
                <button type="submit">Reset</button>
              ) : (
                <Loading style={{ height: 50 }} />
              )}
              <br />
              {this.state.message && (
                <div className="Warning">
                  <br />
                  {this.state.message}
                </div>
              )}
              <br />
            </div>
          ) : (
            <div>
              Sent reset link! Check your email! <br />
              <br />
            </div>
          )}
        </form>
      </div>
    );
  };

  renderConfirm = oobCode => {
    return (
      <div>
        <form onSubmit={event => this.onFormSubmitConfirm(event, oobCode)}>
          <Logo large flat center />
          <br />
          <br />
          <h1>Reset Password</h1>
          <br />
          <input type="password" ref="password" placeholder="Password" />
          <br />
          <br />
          <input
            type="password"
            ref="confirm-password"
            placeholder="Confirm Password"
          />
          <br />
          <br />
          {!this.state.loading ? (
            <button type="submit">Reset</button>
          ) : (
            <Loading style={{ height: 50 }} />
          )}
          <br />
          {this.state.message && (
            <div className="Warning">
              <br />
              {this.state.message}
              <br />
            </div>
          )}
          <br />
        </form>
      </div>
    );
  };

  render() {
    let values = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    return (
      <div className="PagePasswordReset">
        <div className="Box">
          {values.oobCode
            ? this.renderConfirm(values.oobCode)
            : this.renderForm()}
        </div>
        <br />
        <Link to="/login">Already have an account? Log in</Link>
        <br />
        <div style={{ height: 10 }} />
        <Link to="/register">Create an account</Link>
        <br />
      </div>
    );
  }
}
