import {
  takeEvery,
  takeLatest,
  put,
  call,
  all,
  select,
} from 'redux-saga/effects';
import { apiPost, apiGet, apiDelete } from 'utils/Api';
import * as StripeActions from 'store/actions/stripe_actions';

function* getCustomer(action) {
  const stripe_id = action.payload.stripe_id
    ? action.payload.stripe_id
    : yield select(state => state.firebase.profile.stripe_id);

  if (stripe_id) {
    const { json, response } = yield call(apiPost, '/customer', {
      type: 'retrieve',
      user: stripe_id,
    });
    if (response.status === 200) {
      yield put(StripeActions.saveCustomer(json));
    }
  }
}

function* getSubscription(action) {
  const stripe_subscription = action.payload.stripe_subscription
    ? action.payload.stripe_subscription
    : yield select(state => state.firebase.profile.stripe_subscription);

  if (stripe_subscription) {
    const { json, response } = yield call(apiPost, '/subscription', {
      type: 'retrieve',
      id: stripe_subscription,
    });
    if (response.status === 200) {
      yield put(StripeActions.saveSubscription(json));
    }
  }
}

function* cancelSubscription(action) {
  const stripe_subscription = action.payload.id
    ? action.payload.id
    : yield select(state => state.firebase.profile.stripe_subscription);
  if (stripe_subscription) {
    const { json, response } = yield call(apiPost, '/subscription', {
      type: 'cancel',
      id: stripe_subscription,
    });
    if (response.status === 200) {
      yield put(StripeActions.saveSubscription(json));
    }
  }
}

function* uncancelSubscription(action) {
  const stripe_subscription = action.payload.id
    ? action.payload.id
    : yield select(state => state.firebase.profile.stripe_subscription);
  if (stripe_subscription) {
    const { json, response } = yield call(apiPost, '/subscription', {
      type: 'uncancel',
      id: stripe_subscription,
    });
    if (response.status === 200) {
      yield put(StripeActions.saveSubscription(json));
    }
  }
}

function* deleteSubscription(action) {
  const stripe_subscription = action.payload.id
    ? action.payload.id
    : yield select(state => state.firebase.profile.stripe_subscription);
  if (stripe_subscription) {
    const { json, response } = yield call(apiPost, '/subscription', {
      type: 'delete',
      id: stripe_subscription,
    });
    if (response.status === 200) {
      // yield put(StripeActions.saveSubscription(json))
      // yield put(StripeActions.getAll)
    }
  }
}

function* getAll(action) {
  const profile = yield select(state => state.firebase.profile);

  yield put(StripeActions.getCustomer(action.payload.customer));
  yield put(StripeActions.getSubscription(action.payload.subscription));
}

export default function*() {
  yield all([
    takeEvery(StripeActions.DELETE_SUBSCRIPTION, deleteSubscription),
    takeEvery(StripeActions.CANCEL_SUBSCRIPTION, cancelSubscription),
    takeEvery(StripeActions.UNCANCEL_SUBSCRIPTION, uncancelSubscription),
    takeEvery(StripeActions.GET_SUBSCRIPTION, getSubscription),
    takeEvery(StripeActions.GET_CUSTOMER, getCustomer),
    takeEvery(StripeActions.GET_ALL, getAll),
  ]);
}
