import * as React from "react";
import { Route, Switch } from "react-router-dom";

import PageLogin from "./routes/PageLogin";
import PageRegister from "./routes/PageRegister";
import PagePasswordReset from "./routes/PagePasswordReset";
import PageConfirmEmail from "./routes/PageConfirmEmail";

import { PrivateRoute, PublicRoute } from "components/PrivateRoute";
import queryString from "query-string";

class PageAuthLayout extends React.Component {
  render() {
    let values = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    return (
      <div className={"PageAuth"}>
        <Switch>
          <PublicRoute
            {...this.props}
            exact
            path="/login"
            component={PageLogin}
            isLoggedIn={this.props.isLoggedIn}
          />
          <PublicRoute
            {...this.props}
            exact
            path="/register"
            component={PageRegister}
            isLoggedIn={this.props.isLoggedIn}
          />
          <PrivateRoute
            {...this.props}
            exact
            path="/"
            component={PageConfirmEmail}
            isLoggedIn={this.props.isLoggedIn}
          />
          <Route
            path="/(auth|reset)"
            render={() => {
              if (values.mode === "verifyEmail") {
                return (
                  <Route
                    path="/auth"
                    render={() => {
                      return (
                        <PageConfirmEmail
                          {...this.props}
                          oobCode={values.oobCode}
                          isLoggedIn={
                            this.props.isLoggedIn // && !this.props.emailVerified
                          }
                          emailVerified={this.props.emailVerified}
                        />
                      );
                    }}
                  />
                );
              } else {
                //else if (values.mode === "resetPassword"){
                return (
                  <PublicRoute
                    {...this.props}
                    path="/(auth|reset)"
                    component={PagePasswordReset}
                    isLoggedIn={this.props.isLoggedIn}
                  />
                );
              }
            }}
          />
        </Switch>
      </div>
    );
  }
}

export default PageAuthLayout;
