/** @jsx jsx **/

import * as React from "react";
import ReactDOM from "react-dom";
import { jsx } from "@emotion/core";
import { isEmpty } from "react-redux-firebase";
import ReactPlayer from "react-player";
import Modal from "react-bootstrap/Modal";
import ls from "local-storage";
import { roomStyle } from "./styles.js";
import { getTitle } from "utils/helpers";
import { ColorExtractor } from "react-color-extractor";
import Playlist from "components/Playlist";
import { userCount } from "./helpers.js";
import Slider from "react-rangeslider";
import ExtraButtons from "./ExtraButtons";
import InfoHelper from "./InfoHelper";
import Chat from "./Chat";
import ReactTooltip from "react-tooltip";
import { Helmet } from "react-helmet";
import RotateImg from "components/RotateImg";
import { Redirect } from "react-router-dom";

import "./styles.scss";

const secondsToDate = (sec) => {
  if (sec >= 3600) {
    return new Date(sec * 1000).toISOString().substr(11, 8);
  } else {
    return new Date(sec * 1000).toISOString().substr(14, 5);
  }
};

class PageRoomLayout extends React.Component {
  state = {
    newName: "",
    mute: true,
    volume: 0.33,
    scrub: 0,
    scrubSec: "00:00",
    total: "00:00",
    loaded: 0,
    url: "https://www.youtube.com/watch?v=u4ENF5OCtkY",
    songLoaded: false,
    addSong: false,
    songURL: "",
    initialPress: false,
    canClick: false,
    playing: true,
    duration: 0,
  };

  beforeUnload = (ev) => {
    ev.preventDefault();
    return this.props.goOffline();
  };

  onLoad = (duration, paused = false) => {
    if (!this.state.songLoaded || paused) {
      const currentTime = new Date().getTime();
      const seek = this.props.updated
        ? (currentTime - this.props.updated) / 1000
        : 0;
      this.player.seekTo(seek % duration, "seconds");
      this.setState({
        songLoaded: true,
        total: secondsToDate(duration),
        duration,
      });
    } else {
      this.setState({ total: secondsToDate(duration) });
    }
  };

  componentDidMount() {
    this.props.names &&
      this.props.names[this.props.userID] &&
      this.props.goOnline();
    window.addEventListener("beforeunload", this.beforeUnload);

    const rooms = ls.get("rooms");
    ls.set("rooms", { ...rooms, [this.props.rid]: this.props.name });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.radioUpdated !== this.props.radioUpdated) {
      this.player && this.player.seekTo(0, "seconds");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnload);
    this.props.goOffline();
  }

  getColors = (colors) => console.log(colors);

  render() {
    const {
      background,
      name,
      names,
      room,
      userID,
      updateName,
      onEnded,
      location,
      isAdmin,
      disableUser,
    } = this.props;

    const { message, newName, scrub, loaded, volume } = this.state;
    const yourName = names && names[userID];

    if (isEmpty(room)) {
      return (
        <div css={roomStyle}>
          <Redirect to="/" />
        </div>
      );
    }

    return (
      <div css={roomStyle}>
        <Helmet>
          <title>{name}</title>
          <meta
            property="og:url"
            href={window.location.origin + window.location.pathname}
          />
          <meta property="og:image" content={background} />
        </Helmet>
        <ReactTooltip place={"bottom"} />
        {/*<ColorExtractor getColors={this.getColors}>*/}
        <RotateImg
          alt="wallpaper"
          className="wallpaper no-drag"
          src={(location.state && location.state.background) || background}
        />
        <ExtraButtons {...this.props} />
        <h1>{name}</h1>
        <InfoHelper {...this.props} />
        <div
          className={
            yourName && this.state.mute ? "DJoverlay" : "DJoverlay -hidden"
          }
          onClick={() => {
            if (this.state.canClick) {
              if (!this.state.playing) {
                this.onLoad(this.state.duration, true);
              }

              this.setState({
                mute: false,
                volume: volume === 0 ? 0.33 : volume,
                playing: true,
              });
            }
          }}
          style={{
            cursor: this.state.canClick ? "pointer" : "auto",
          }}
        >
          <h3>Muted for you</h3>
          <div>{this.state.canClick ? "press to unmute" : "loading..."}</div>
        </div>
        <div className={yourName ? "DJ gradient-bottom" : "DJ blur"}>
          <div
            className="ReactPlayer"
            onClick={() =>
              this.setState({
                mute: !this.state.mute,
              })
            }
            style={{
              display: this.props.showVideo ? "block" : "none",
              opacity: this.state.mute ? 0.8 : 1,
            }}
          >
            <ReactPlayer
              className="react-player"
              ref={(player) => {
                this.player = player;
              }}
              config={{
                youtube: { preload: true },
                soundcloud: { preload: true },
                vimeo: { preload: true },
              }}
              onReady={() => this.setState({ canClick: true })}
              onPause={() => {
                this.setState({ mute: true, playing: false });
              }}
              style={{ margin: "0 auto" }}
              width="100%"
              height="100%"
              controls={false}
              onDuration={this.onLoad}
              url={this.props.song || this.props.default || this.state.url}
              loop={this.props.song ? false : true}
              progressInterval={1000}
              onProgress={({ played, loaded, playedSeconds }) => {
                this.setState({
                  scrub: played,
                  scrubSec: secondsToDate(playedSeconds),
                  loaded,
                });
              }}
              onEnded={this.props.onEnded}
              volume={this.state.volume}
              muted={this.state.mute}
              playing={this.state.playing}
            />
          </div>
          <div className="playcard text-shadow">
            <i
              className={this.props.song ? "fas fa-music" : "fas fa-redo-alt"}
            ></i>{" "}
            {this.props.song ? (
              `LIVE • ${this.props.song}`
            ) : (
              <span>[Default] Add videos to the QUEUE!</span>
            )}
          </div>
          <div style={{ height: 10 }} />
          <div className="scrub">
            <div className="loaded" style={{ width: loaded * 100 + "%" }} />
            <div className="progress" style={{ width: scrub * 100 + "%" }} />
          </div>
          <div style={{ height: 10 }} />
          <div className="row text-shadow">
            <div className="time">
              {this.state.scrubSec} / {this.state.total}
            </div>
            <div
              data-tip={"adjust volume (only for you)"}
              style={{ width: 120 }}
            >
              <Slider
                value={parseInt(this.state.volume * 100)}
                orientation="horizontal"
                onChange={(val) =>
                  this.setState({ volume: val / 100, mute: false })
                }
              />
            </div>
            {(!disableUser || isAdmin) && (
              <div className="controls">
                <i
                  data-tip={"add a video to queue"}
                  className="fas fa-plus-square"
                  onClick={() => this.setState({ addSong: true })}
                ></i>
                <i
                  data-tip={"toggle video (affects everyone)"}
                  className={
                    this.props.showVideo ? "fas fa-video" : "fas fa-video-slash"
                  }
                  onClick={() => this.props.toggleShow(!this.props.showVideo)}
                ></i>
                <i
                  data-tip={"skip video (affects everyone)"}
                  className="fas fa-step-forward"
                  onClick={() => this.props.onEnded(true)}
                ></i>
              </div>
            )}
          </div>
          <Playlist
            show={!disableUser || isAdmin}
            roomID={this.props.rid}
            timestamp={room.radioUpdated}
            sendMessage={this.props.sendMessage}
          />
          <div
            className="add-button-big"
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              cursor: "pointer",
              padding: 10,
              maxWidth: 500,
              margin: "0 auto",
            }}
            onClick={() => this.setState({ addSong: true })}
          >
            <b>
              <i className="fas fa-plus"></i> Add to the queue
            </b>
          </div>
          <br />
          {!disableUser || isAdmin ? (
            <Modal
              centered
              show={this.state.addSong}
              onHide={() => this.setState({ addSong: false, songURL: "" })}
            >
              <input
                placeholder={"Paste in a Youtube link..."}
                style={{ border: "none" }}
                value={this.state.songURL}
                onChange={(e) => this.setState({ songURL: e.target.value })}
              />
              <br />
              <button
                style={
                  this.state.songURL
                    ? {}
                    : { opacity: 0.7, pointerEvents: "none" }
                }
                onClick={() => {
                  if (ReactPlayer.canPlay(this.state.songURL)) {
                    this.setState({ addSong: false, songURL: "" });
                    this.props.addSong(this.state.songURL);
                  } else {
                    alert("Invalid URL");
                  }
                }}
              >
                ADD TO QUEUE
              </button>
            </Modal>
          ) : (
            <Modal
              centered
              show={this.state.addSong}
              onHide={() => this.setState({ addSong: false, songURL: "" })}
            >
              <span>
                Adding to the queue has been disabled by this room's creator.
                <a href={window.location.origin} target="_blank">
                  Create your own room and start your own hangout!
                </a>
              </span>
            </Modal>
          )}
        </div>
        <Chat {...this.props} />
        <div className={yourName ? "name -hidden" : "name"}>
          <div style={{ marginBottom: 20, fontWeight: 600 }}>
            {yourName
              ? "Edit your name to rejoin the chat"
              : "Enter your name to join the room"}
          </div>
          <input
            placeholder={"your name"}
            value={newName}
            onKeyPress={(e) => {
              if (newName && e.key === "Enter") {
                this.setState({ mute: false });
                this.props.goOnline();
                updateName(newName);
              }
            }}
            onChange={(e) => this.setState({ newName: e.target.value })}
          />
          <button
            className={newName ? "name-submit" : "name-submit -hidden"}
            onClick={() => {
              if (newName && this.state.canClick) {
                this.setState({ mute: false });
                this.props.goOnline();
                updateName(newName);
              }
            }}
          >
            {this.state.canClick ? "Enter the room" : "Loading..."}
          </button>
        </div>
      </div>
    );
  }
}

export default PageRoomLayout;
