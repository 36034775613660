import * as React from "react";
import { connect } from "react-redux";
import PageHomeLayout from "./layout";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ReactPlayer from "react-player";
import ls from "local-storage";

class PageHome extends React.Component {
  state = { rooms: {} };

  componentDidMount() {
    const rooms = ls.get("rooms");
    this.setState({ rooms });
  }

  removeRoom = (id) => {
    const rooms = ls.get("rooms");
    delete rooms[id];
    ls.set("rooms", { ...rooms });
    this.setState({ rooms });
  };

  render() {
    return (
      <PageHomeLayout
        {...this.props}
        {...this.props.item}
        rooms={this.state.rooms}
        removeRoom={this.removeRoom}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const { userID } = props;

  return {
    createRoom: async (data) => {
      const {
        roomID,
        roomName,
        description,
        yourName,
        background,
        tempBackground,
        customBackground,
        video,
        allowUser
      } = data;

      const pushProps = {};

      if (video && !ReactPlayer.canPlay(video)) {
        alert("Unsupported video link. YouTube links only.");
      }

      if (roomName && yourName) {
        const newRoomID = roomName.replace(/\s/g, "-") + "-" + roomID;
        const admin = uuidv4().substring(0, 6);
        const newBackground = tempBackground === background;
        let uploadBackground = background;

        if (newBackground) {
          const picID = uuidv4();
          const { uploadTaskSnapshot } = await props.firebase.uploadFile(
            "/rooms",
            customBackground,
            null,
            {
              name: picID + ".jpg",
            }
          );
          const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
          pushProps["background"] = downloadURL;
          uploadBackground = downloadURL.replace(".jpg", "_1400x1400.jpg");
        }

        const previousRoom =
          (props.location.state && props.location.state.room) || {};

        const query = {
          name: roomName,
          admin: previousRoom.admin || admin,
          description,
          background: uploadBackground,
          showVideo: true,
          default: video,
          disableUser: !allowUser,
        };

        if (!previousRoom.name) {
          query["updated"] = new Date().getTime();
          query["radioUpdated"] = new Date().getTime();
          query["owner"] = userID;
          // query["names"] = { [userID]: yourName };
        }

        await props.firebase.update(
          "/rooms/" + (previousRoom.roomID || newRoomID),
          query
        );

        await props.firebase.update(
          "/rooms/" + (previousRoom.roomID || newRoomID) + "/names",
          { [userID]: yourName }
        );

        props.history.push(
          "/r/" +
            (previousRoom.roomID || newRoomID) +
            "?admin=" +
            (previousRoom.admin || admin),
          pushProps
        );
        // props.history.push(
        //   "/r/" + (previousRoom.roomID || newRoomID),
        //   pushProps
        // );
      } else {
        alert("Your room requires a name and your name");
      }
    },
  };
}

export default compose(
  withRouter,
  firebaseConnect(),
  firestoreConnect(),
  connect(mapStateToProps)
)(PageHome);
