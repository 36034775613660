import * as React from "react";
import firebase from "firebase";
import { connect } from "react-redux";
import PageRoomLayout from "./layout";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { isLoaded } from "react-redux-firebase";
import { roomStyle } from "./styles.js";
import queryString from "query-string";
import Loading from "components/Loading";

class PageRoom extends React.Component {
  render() {
    if (!isLoaded(this.props.room)) {
      return (
        <div
          css={roomStyle}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Loading color={"white"} type="spin" style={{ width: 400 }} />
        </div>
      );
    }

    if (this.props.names && this.props.names[this.props.userID] === "blocked!") {
      return <div>You've been removed from this room</div>
    }

    return (
      <PageRoomLayout
        {...this.props}
        {...this.props.item}
        {...this.props.room}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const room = state.firebase.data.room || {};
  // const radio = state.firebase.ordered.radio;
  const sendMessage = (message) => {
    const mid = uuidv4();
    message &&
      props.firebase.update("/chat/" + props.rid + "/" + mid, {
        id: mid,
        text: message,
        uid: props.userID,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
      });
  };

  const values = queryString.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const isAdmin =
    values["admin"] === room["admin"] || room["owner"] === props.userID;

  return {
    isAdmin: isAdmin,
    room: state.firebase.data.room,
    stats: state.firebase.data.stats || {},
    chat: state.firebase.ordered.chat || [],
    // radio: state.firebase.ordered.radio || [],
    // isLoggedIn: state.firebase.auth.uid ? true : false,
    isLoggedIn: true,
    sendMessage: sendMessage,
    editRoom: () => {
      if (isAdmin) {
        // sendMessage(`**is editing this room**`);
        props.history.push("/?type=edit", {
          room: {
            ...state.firebase.data.room,
            roomID: props.rid,
            editorName: room.names[props.userID],
            create: true,
          },
        });
      }
    },
    updateName: async (name) => {
      if (name && name !== 'blocked!') {
        // sendMessage(`**${name} changed their name**`);
        await props.firebase.update("/rooms/" + props.rid + "/names", {
          [props.userID]: name,
        });
      }
    },
    toggleShow: (state) => {
      sendMessage(`**turned video ${state ? "on" : "off"}**`);
      props.firebase.update("/rooms/" + props.rid, { showVideo: state });
    },
    goOnline: () => {
      sendMessage(`**has entered the room**`);
      props.firebase.update("/stats/" + props.rid, {
        [props.userID]: true,
      });
    },
    goOffline: () => {
      // sendMessage(`**has left the room**`);
      props.firebase.update("/stats/" + props.rid, {
        [props.userID]: null,
      });
    },
    removeMember: (id) => {
      // sendMessage(`**removed ${room.names[id] || "user"} from room**`);
      props.firebase.update("/stats/" + props.rid, { [id]: null });
      props.firebase.update("/rooms/" + props.rid + "/names", { [id]: null });
    },
    blockMember: (id) => {
      props.firebase.update("/stats/" + props.rid, { [id]: null });
      props.firebase.update("/rooms/" + props.rid + "/names", { [id]: 'blocked!' });
    },
    onEnded: async (skip = false) => {
      if (skip) sendMessage(`**skipped video**`);
      await props.firebase.functions().httpsCallable("radio-nextSong")({
        roomID: props.rid,
        radioUpdated: room.radioUpdated,
      });
    },
    addSong: async (url) => {
      sendMessage(`**added ${url} to the playlist**`);
      await props.firebase.functions().httpsCallable("radio-addSong")({
        roomID: props.rid,
        url,
        owner: props.userID,
        admin: values["admin"],
      });
    },
  };
}

export default compose(
  withRouter,
  firebaseConnect((props) => [
    {
      path: "/rooms/" + props.rid,
      storeAs: "room",
    },
    {
      path: "/chat/" + props.rid,
      storeAs: "chat",
      queryParams: ["orderByChild=timestamp", "limitToLast=200"],
    },
    {
      path: "/stats/" + props.rid,
      storeAs: "stats",
    },
  ]),
  firestoreConnect(),
  connect(mapStateToProps)
)(PageRoom);
