import * as React from "react";

export const userCount = ({ stats, names, userID }) => {
  const userNames = [];
  const uids = Object.keys(stats);
  let youreOnline = false;

  uids.forEach((id) => {
    if (id !== userID && names[id]) userNames.push(names[id]);
    if (id === userID) {
      youreOnline = true;
    }
  });

  let returnVal = "";

  if (!youreOnline) {
    returnVal = (
      <div className="count-label">
        {uids.length}{" "}
        {uids.length === 1 ? " person is online" : " people are online"}
      </div>
    );
  }

  if (youreOnline) {
    if (userNames.length === 0 && uids.length <= 1) {
      returnVal = (
        <div className="count-label">
          <b>You're</b> online
        </div>
      );
    }
    if (userNames.length === 0 && uids.length > 1) {
      returnVal = (
        <div className="count-label">
          <b>You're</b> online with <b>{uids.length - 1}</b> other{" "}
          {uids.length === 2 ? "person" : "people"}
        </div>
      );
    } else if (userNames.length === 1 && uids.length <= 2) {
      returnVal = (
        <div className="count-label">
          <b>You</b> and <b>{userNames[0]}</b> are online
        </div>
      );
    } else if (userNames.length === 1 && uids.length > 2) {
      returnVal = (
        <div className="count-label">
          <b>You</b> and <b>{userNames[0]}</b> are online with{" "}
          <b>{uids.length - 2}</b> other{" "}
          {uids.length === 3 ? "person" : "people"}
        </div>
      );
    } else if (userNames.length === 2 && uids.length <= 3) {
      returnVal = (
        <div className="count-label">
          <b>You</b>, <b>{userNames[0]}</b>, and <b>{userNames[1]}</b> are
          online
        </div>
      );
    } else if (userNames.length === 2 && uids.length > 3) {
      returnVal = (
        <div className="count-label">
          <b>You</b>, <b>{userNames[0]}</b>, and <b>{userNames[1]}</b> are
          online with <b>{uids.length - 3}</b> other{" "}
          {uids.length === 4 ? "person" : "people"}
        </div>
      );
    } else if (userNames.length >= 3) {
      returnVal = (
        <div className="count-label">
          <b>You</b>, <b>{userNames[0]}</b>, and <b>{userNames[1]}</b> are
          online with <b>{uids.length - 3}</b> other{" "}
          {uids.length === 4 ? "person" : "people"}
        </div>
      );
    }
  }

  return returnVal;
};
