import React from "react";
import { Redirect, Route } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = rest.isLoggedIn;
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} {...props.match.params} {...rest} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export const PublicRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = rest.isLoggedIn;
  return (
    <Route
      {...rest}
      render={props =>
        !isLoggedIn ? (
          <Component {...props} {...props.match.params} {...rest} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export const CustomRoute = ({ component: Component, ...rest }) => {
  const redirect = rest.redirect;
  const pathname = rest.pathname;

  return (
    <Route
      {...rest}
      render={props =>
        !redirect ? (
          <Component {...props} {...props.match.params} {...rest} />
        ) : (
          <Redirect
            to={{ pathname: pathname || "/", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
