import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "components/Logo";
import queryString from "query-string";
import Loading from "components/Loading";

export default class PageConfirmEmail extends Component {
  state = {
    resent: false,
    loading: false,
    verfied: false,
    error: this.props.oobCode
      ? "Verifying your email..."
      : "A verification email has been sent to your email address, " +
        this.props.email +
        "."
  };

  verifyEmail = () => {
    let values = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    if (values.oobCode) {
      this.props.firebase
        .auth()
        .applyActionCode(values.oobCode)
        .then(() => {
          this.setState({ verified: true, error: "Verified!" });
          if (this.props.isLoggedIn) {
            // window.location.reload();
            this.props.history.push("/profile");
          }
        })
        .catch(err => {
          this.setState({ error: err.message });
        });
    }
  };

  // componentDidUpdate() {
  //   this.verifyEmail();
  // }

  componentDidMount() {
    this.verifyEmail();
    if (this.props.emailVerified) {
      this.props.history.push("/");
    }
  }

  renderConfirm = () => {
    if (this.props.isLoggedIn) {
      if (this.props.emailVerified) {
        return (
          <div>
            You're verified!{" "}
            <Link to="/" style={{ fontFamily: "apercu", color: "black" }}>
              Click here get started!
            </Link>
          </div>
        );
      }

      if (this.state.loading) {
        return <Loading style={{ height: 50 }} />;
      } else {
        if (!this.state.resent) {
          return (
            <button
              onClick={() => {
                this.setState({ loading: true });
                this.props.firebase
                  .auth()
                  .currentUser.sendEmailVerification()
                  .then(() => {
                    this.setState({
                      resent: true,
                      loading: false,
                      error:
                        "Verification email resent. Please check your inbox."
                    });
                  })
                  .catch(err => {
                    this.setState({ error: err.message, loading: false });
                  });
              }}
            >
              resend verification email
            </button>
          );
        }
      }
    } else {
      return (
        <div>
          {this.state.verified ? (
            <div>
              You've been verified! Log back in by clicking{" "}
              <Link
                to="/login"
                style={{ fontFamily: "apercu", color: "black" }}
              >
                here
              </Link>{" "}
              to get started!
            </div>
          ) : (
            <div>
              <Link to="/">Go back home</Link>
              <br />
              <br />
              <a
                onClick={() => {
                  this.props.logoutUser();
                  this.props.history.push("/");
                }}
                style={{ cursor: "pointer" }}
              >
                Logout
              </a>
            </div>
          )}
        </div>
      );
    }
  };

  render() {
    return (
      <div className="PageConfirmEmail">
        <div className="Box">
          <Logo large flat center />
          <br />
          <br />
          <h1>Confirm Your Email</h1>
          <br />
          <div>{this.state.error}</div>
          <br />
          <br />
          {this.renderConfirm()}
          <br />
          <br />
        </div>
        {this.props.isLoggedIn && (
          <button
            onClick={this.props.logoutUser}
            style={{
              background: "none",
              color: "rgba(0, 0, 0, 0.5)",
              fontSize: 14
            }}
          >
            logout
          </button>
        )}
      </div>
    );
  }
}
