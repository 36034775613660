/*

Framework created by Ryan Lee, use only allowed with permission from Ryan Lee.

*/

import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "styles/styles.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { applyMiddleware, createStore, compose } from "redux";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";

import { getFirebase } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";

import firebase from "./utils/Firebase";

import rootReducer from "store/reducers";
import root from "store/sagas";

require("dotenv").config();

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

let middleware = [routerMiddleware(history), sagaMiddleware];

const rrfConfig = {
  userProfile: "users"
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  // useFirestoreForStorageMeta: true
};

const store = createStore(
  rootReducer,
  {},
  compose(applyMiddleware(...middleware))
);

const rrfProps = {
  firebase: firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
};

sagaMiddleware.run(root, getFirebase);

ReactDOM.render(
  <App store={store} rrfProps={rrfProps} />,
  document.getElementById("root")
);

serviceWorker.unregister();
