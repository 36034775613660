/** @jsx jsx **/

import * as React from "react";
import { jsx, css } from "@emotion/core";
import { v4 as uuidv4 } from "uuid";
import Modal from "react-bootstrap/Modal";
import RotateImg from "components/RotateImg";

import Loading from "components/Loading";

import { backgrounds } from "constants/background";

const homeStyle = css`
  height: 100%;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-color: black;

  .wallpaper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    object-fit: cover;

    &.-rotate,
    &.-eight,
    &.-six {
      height: 100vw;
      width: 100vh;
    }
  }

  .panel {
    position: relative;
    width: calc(100% - 40px);
    max-width: 400px;
    height: calc(100% - 40px);
    max-height: 600px;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    color: white;
    padding: 20px;
    text-align: center;

    h3 {
      margin-bottom: 20px;
    }

    input,
    textarea {
      width: 100%;
      border: 0;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 8px 12px;
    }

    textarea {
      height: 80px;
      resize: none;
      vertical-align: top;
    }

    .sub-panel {
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .button-panel {
      // position: fixed;
      // bottom: max(20px, calc(50% - 300px));
      // left: max(20px, calc(50% - 200px));
      // width: calc(100% - 40px);
      width: 100%;
      left: 0;
      position: absolute;
      bottom: 0;
      max-width: 400px;
      padding: 20px;
    }

    .create {
      color: white;
      background: rgba(0, 0, 0, 0.8);
      padding: 10px 20px;
      width: 100%;

      &:hover {
        background: rgba(0, 0, 0, 1);
      }
    }
  }

  .link {
    display: flex;
    align-items: center;
    .label {
      margin-right: 10px;
    }
  }

  .backgrounds {
    margin: 20px 0 66px;
    width: 100%;
    display: grid;
    grid-column-gap: 4px;
    grid-row-gap: 4px;
    grid-template-columns: 1fr 1fr 1fr;

    .background {
      background: rgba(0, 0, 0, 0.5);
      position: relative;
      min-width: 0px;
      width: 100%;
      object-fit: cover;
      height: 80px;
      cursor: pointer;
      // height: 40px;

      &.-upload {
      }
    }

    label {
      margin-bottom: 0;
      position: relative;
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        opacity: 0.6;
        margin-top: 2px;
        font-size: 10px;
        font-weight: 600;
      }

      &.-temp {
        width: auto;
        height: auto;
      }

      &:hover i {
        opacity: 0.9;
      }
    }

    .hover {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i {
        opacity: 0.6;
        font-size: 30px;
      }
    }
  }

  .selection {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:hover {
      border: 4px solid lightgrey;
    }

    &.-selected {
      border: 4px solid grey;
    }
  }
`;

class PageHomeLayout extends React.Component {
  constructor(props) {
    super(props);

    const room =
      (this.props.location.state && this.props.location.state.room) || {};

    this.state = {
      edit: room.name ? true : false,
      roomName: room.name || "",
      background: room.background || backgrounds[0],
      yourName: room.editorName || "",
      roomID: uuidv4().substring(0, 8),
      description: "",
      customBackground: null,
      tempBackground: null,
      loading: false,
      video: room.default || "",
      link: "",
      showLink: false,
      tempLink: "",
      create: room.create,
      allowUser: !room.disableUser,
    };
  }

  render() {
    const { createRoom, rooms } = this.props;
    const {
      roomName,
      roomID,
      background,
      yourName,
      description,
      tempBackground,
      loading,
      location,
      video,
      link,
    } = this.state;

    if (loading) {
      return (
        <div css={homeStyle} style={{ backgroundImage: `url(${background})` }}>
          <Loading color={"grey"} type="spin" style={{ width: 400 }} />
        </div>
      );
    }

    if (rooms && Object.keys(rooms).length > 0 && !this.state.create) {
      return (
        <div css={homeStyle}>
          <RotateImg
            alt="wallpaper"
            className="wallpaper no-drag"
            src={background}
          />
          <div className="panel">
            <div
              className="sub-panel"
              style={{ overflow: "scroll", height: "100%" }}
            >
              <h3>{"Rejoin a room"}</h3>
              <div style={{ height: 10 }} />
              {Object.keys(rooms).map((key) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        marginBottom: 0,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(100% - 20px)",
                      }}
                      onClick={() => this.props.history.push("/r/" + key)}
                    >
                      <h4
                        style={{
                          marginBottom: 0,
                          maxWidth: "calc(100% - 50px)",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {rooms[key]}{" "}
                      </h4>
                      <i
                        className="fas fa-arrow-right"
                        style={{ fontSize: 16, marginLeft: 10 }}
                      ></i>
                    </div>
                    <h4 style={{ cursor: "pointer", marginBottom: 0 }}>
                      <i
                        onClick={() => {
                          this.props.removeRoom(key);
                        }}
                        className="fas fa-times"
                        style={{ fontSize: 16 }}
                      ></i>
                    </h4>
                  </div>
                );
              })}
              <div style={{ height: 100 }} />
            </div>
            <div className="button-panel">
              <button
                className="create"
                onClick={async () => {
                  await this.setState({ create: true });
                }}
              >
                <b>{"Create a new room"}</b>
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div css={homeStyle}>
        {/*<img alt="wallpaper" className="wallpaper no-drag" src={background} />*/}
        <RotateImg
          alt="wallpaper"
          className="wallpaper no-drag"
          src={background}
        />
        <div className="panel">
          <div
            className="sub-panel"
            style={{ overflow: "scroll", height: "100%" }}
          >
            <h3>{this.state.edit ? "Edit room" : "Create a room"}</h3>
            <input
              value={roomName}
              onChange={(e) => this.setState({ roomName: e.target.value })}
              placeholder={"Your room name (eg. Study session)"}
            />
            <br />
            <br />
            {/*<textarea
            value={description}
            onChange={e => this.setState({ description: e.target.value })}
            placeholder={"Give your room a description"}
          />*/}
            <input
              value={yourName}
              onChange={(e) => this.setState({ yourName: e.target.value })}
              placeholder={"Your name (eg. John Smith)"}
            />
            <br />
            <br />
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: 14, marginRight: 10, textAlign: "left" }}>
                Allow visitors to update video queue
              </div>
              <div
                style={{
                  height: 24,
                  width: 24,
                  background: "rgba(0, 0, 0, 0.5)",
                  position: "relative",
                  cursor: "pointer",
                }}
                onClick={() =>
                  this.setState({ allowUser: !this.state.allowUser })
                }
              >
                {this.state.allowUser && (
                  <i className="fas fa-check" style={{ color: "white" }}></i>
                )}
              </div>
            </div>
            {/*<br />
            <input
              value={video}
              onChange={(e) => this.setState({ video: e.target.value })}
              placeholder={"Custom background music (YouTube link)"}
            />
            <br />*/}
            {/*<div className="link">
            <div className="label">Share: </div>
            <input
              value={window.location.origin + "/" + roomID}
              onFocus={event => event.target.select()}
              readOnly
            />
          </div>*/}
            <div className="backgrounds">
              <div className="upload" style={{ position: "relative" }}>
                <img
                  alt="background"
                  className="background -upload"
                  src={link || require("assets/empty.svg")}
                />
                <div
                  className={
                    link === background
                      ? "selection hover -selected"
                      : "selection hover"
                  }
                  onClick={() => link && this.setState({ background: link })}
                >
                  <label
                    className={link ? "-temp" : ""}
                    onClick={() =>
                      this.setState({ showLink: true, tempLink: link })
                    }
                  >
                    <i className="fas fa-camera"></i>
                    <span>image url</span>
                  </label>
                </div>
                <Modal
                  centered
                  show={this.state.showLink}
                  onHide={() => this.setState({ showLink: false })}
                >
                  <input
                    placeholder={"Paste in an image link..."}
                    style={{ border: "none" }}
                    value={this.state.tempLink}
                    onChange={(e) =>
                      this.setState({ tempLink: e.target.value })
                    }
                  />
                  <br />
                  <button
                    style={
                      this.state.tempLink
                        ? {}
                        : { opacity: 0.7, pointerEvents: "none" }
                    }
                    onClick={() => {
                      this.setState({
                        tempLink: "",
                        showLink: false,
                        background: this.state.tempLink,
                        link: this.state.tempLink,
                      });
                    }}
                  >
                    SET BACKGROUND
                  </button>
                </Modal>
              </div>
              <div className="upload" style={{ position: "relative" }}>
                <img
                  alt="background"
                  className="background -upload"
                  src={tempBackground || require("assets/empty.svg")}
                />
                <div
                  className={
                    tempBackground === background
                      ? "selection hover -selected"
                      : "selection hover"
                  }
                  onClick={() =>
                    tempBackground &&
                    this.setState({ background: tempBackground })
                  }
                >
                  <label className={tempBackground ? "-temp" : ""}>
                    <input
                      accept="image/*"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        if (e.target.files[0]) {
                          const imageURL = URL.createObjectURL(
                            e.target.files[0]
                          );
                          this.setState({
                            background: imageURL,
                            tempBackground: imageURL,
                            customBackground: e.target.files[0],
                          });
                        }
                      }}
                    />
                    <i className="fas fa-file-upload"></i>
                    <span>image file</span>
                  </label>
                </div>
              </div>
              {backgrounds.map((bg) => (
                <div style={{ position: "relative" }}>
                  <img
                    alt="background"
                    className="background"
                    src={bg}
                    onClick={() => this.setState({ background: bg })}
                  />
                  <div
                    className={
                      bg === background ? "selection -selected" : "selection"
                    }
                    onClick={() => this.setState({ background: bg })}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="button-panel">
            <button
              className="create"
              onClick={async () => {
                await this.setState({ loading: true });
                await createRoom({ roomID, ...this.state });
                await this.setState({ loading: false });
              }}
            >
              <b>{this.state.edit ? "Finish editing" : "Create!"}</b>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default PageHomeLayout;
