import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Mixpanel } from "utils/mixpanel";
import Loading from "components/Loading";
import GoogleButton from "components/GoogleButton";
import Logo from "components/Logo";

export default class PageLogin extends Component {
  constructor(props) {
    super(props);
    this.state = { message: "", loading: false };
  }

  onFormSubmit = event => {
    event.preventDefault();

    const email = this.refs.email.value;
    const password = this.refs.password.value;

    this.setState({ loading: true });

    this.props
      .loginUser({ email, password })
      .then(data => {
        Mixpanel.identify(email);
        Mixpanel.track("user_login", { email: email });
      })
      .catch(error => {
        this.setState({ message: error.message, loading: false });
      });
  };

  loginWithProvider = provider => {
    this.setState({ loading: true });

    this.props
      .loginUser({ provider })
      .then(data => {
        let email = data.user.user.email;
        Mixpanel.identify(email);
        Mixpanel.track("user_login", { email: email });
      })
      .catch(error => {
        this.setState({ message: error.message, loading: false });
      });
  };

  render() {
    return (
      <div className="PageLogin">
        <div className="Box">
          <form onSubmit={this.onFormSubmit}>
            <Logo large flat center />
            <br />
            <br />
            <h1>Log In</h1>
            <br />
            <input type="email" ref="email" placeholder="Email" />
            <br />
            <br />
            <input type="password" ref="password" placeholder="Password" />
            <br />
            <br />
            {!this.state.loading ? (
              <div>
                <button type="submit">Log In</button>
                <br />
                {this.state.message && (
                  <div className="Warning">
                    <br />
                    {this.state.message}
                  </div>
                )}
                <br />
                <GoogleButton
                  onClick={() => {
                    this.loginWithProvider("google");
                  }}
                />
              </div>
            ) : (
              <Loading style={{ height: 148 }} />
            )}
          </form>
        </div>
        <br />
        <Link to="/reset">Forgot password? Reset</Link>
        <br />
        <div style={{ height: 10 }} />
        <Link to="/register">Create an account</Link>
        <br />
      </div>
    );
  }
}
