import React, { Component } from "react";
import ReactLoading from "react-loading";

export default class Loading extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...this.props.style
        }}
      >
        <ReactLoading
          className={"Loading"}
          type={this.props.type ? this.props.type : "bubbles"}
          width={this.props.width || "30%"}
          height={100}
          color={this.props.color || "black"}
        />
      </div>
    );
  }
}
